import { Text, TextVariant } from '@naf/text';
import { Grid } from '@naf/grid';
import React from 'react';
import styled from 'styled-components';
import { breakpoints, spacing } from '@naf/theme';
import { EventType } from '../../../../../types/EventType';
import BreadCrumb from '../../../components/breadcrumb/BreadCrumb';
import { useDocument } from '../../../hooks/useDocument';
import { LoaderContent } from '../../LoaderPage';
import OrganizerHeader from '../components/OrganizerHeader';
import EventDetails from '../components/EventDetails';
import AvailabilityInfoBox from '../components/AvailabilityInfoBox';
import BlockContent from '../../../components/block-content/BlockContent';
import { FormBlock } from '../../../components/block-content/components/Form/FormBlock';
import { useAuth0Token } from '../../../hooks/useAuth0Token';
import EventImage from '../components/EventImage';
import { useWindowLocation } from '../../../hooks/Window/useWindowLocation';
import { useSendGTMEventOnce } from '../../../hooks/GTM/useSendGTMEventOnce';
import { useHandleNafLocationsRoutes } from '../useHandleNafLocationsRoutes';
import { InternalSearchCardType } from '../../../components/layout/InternalSearchMetaData';
import { LayoutWithMainContent } from '../../../components/layout/Layout';
import { NotFound } from '../../../loadable-elements/NotFound';
import SchemaEvent from '../../../components/structured-data/SchemaEvent';

type Props = {
  match: {
    params: { slug: string };
  };
};

const EventPage = ({
  match: {
    params: { slug },
  },
}: Props) => {
  const { simpleToken } = useAuth0Token();
  const { data, meta } = useDocument(slug, simpleToken);
  const url = useWindowLocation();
  const { locations } = useHandleNafLocationsRoutes();

  useSendGTMEventOnce(
    data && {
      page_type: 'nafLocationsApp',
      pageCategory: `Her finner du NAF / ${Object.values(locations).find((l) => url.includes(l.fullUrl))?.name} / ${
        (data as EventType).title
      }`,
      contentId: slug,
    },
    [data],
  );

  if (!data && meta.isUpdating) {
    return <LoaderContent />;
  }
  if (!data) {
    return <NotFound />;
  }
  const {
    title,
    organizer,
    ingress,
    image,
    dateTimeIntervals,
    locationName,
    locationAddress,
    coordinates,
    availabilityInfo,
    body,
    registrationInfoText,
    dynamicRegistrationForm,
    priceNotMember,
    priceMember,
    seoConfig,
  } = data as EventType;
  return (
    <LayoutWithMainContent
      title={title}
      description={seoConfig?.introduction || ingress}
      seoDescription={seoConfig?.introduction || undefined}
      isHiddenFromSearch={seoConfig?.isHiddenFromSearch}
      seoTitle={seoConfig?.title || title}
      internalSearchMetaData={{
        cardType: InternalSearchCardType.Event,
        organizerTitle: organizer?.title,
        imageUrl: image?.url,
      }}
      imgUrl={image.url}
      url={url}
    >
      <SchemaEvent
        title={title}
        address={locationAddress}
        locationName={locationName}
        coordinates={coordinates}
        dateTimeIntervals={dateTimeIntervals}
      />
      <StyledGrid>
        <Content>
          <StyledBreadCrumb />
          {organizer && <OrganizerHeader organizer={organizer} />}
          <StyledTitle variant={TextVariant.Header1}>{title}</StyledTitle>
          <Ingress variant={TextVariant.Ingress}>{ingress}</Ingress>
          <StyledAvailabilityInfoBox availabilityInfo={availabilityInfo} organizer={organizer} />
        </Content>
        {image.url && <StyledImage image={image} />}
        <Content>
          <EventDetails
            dateTimeIntervals={dateTimeIntervals}
            locationName={locationName}
            locationAddress={locationAddress}
            coordinates={coordinates}
            priceNotMember={priceNotMember}
            priceMember={priceMember}
          />
          {registrationInfoText && registrationInfoText?.length > 0 && (
            <>
              <Text variant={TextVariant.Header2}>Påmelding</Text>
              <BlockContent value={registrationInfoText} />
            </>
          )}
          {dynamicRegistrationForm && (
            <>
              <TextNoMargin variant={TextVariant.Header2}>Påmelding</TextNoMargin>
              <FormBlock value={{ _key: '123', _type: 'dynamicForm', dynamicForm: dynamicRegistrationForm }} />
            </>
          )}
          <Text variant={TextVariant.Header2}>Om arrangementet</Text>
          <BlockContent value={body} />
        </Content>
      </StyledGrid>
    </LayoutWithMainContent>
  );
};

const StyledGrid = styled(Grid)`
  row-gap: ${spacing.space48};
`;

const Content = styled.div`
  grid-column: 4/10;

  @media (max-width: ${breakpoints.l}) {
    grid-column: 1 / 13;
  }
`;

const StyledBreadCrumb = styled(BreadCrumb)`
  margin-bottom: ${spacing.space32};

  @media (min-width: ${breakpoints.xl}) {
    margin-bottom: ${spacing.space48};
  }
`;

const StyledAvailabilityInfoBox = styled(AvailabilityInfoBox)`
  margin-top: ${spacing.space40};
`;

const StyledTitle = styled(Text)`
  margin: ${spacing.space16} 0;
`;

const Ingress = styled(Text)`
  margin: 0;
`;

const StyledImage = styled(EventImage)`
  width: 100%;
  height: auto;
  grid-column: 3/11;

  @media (max-width: ${breakpoints.l}) {
    grid-column: 1 / 13;
  }
`;

const TextNoMargin = styled(Text)`
  margin-bottom: 0;
`;

export default EventPage;
