import React from 'react';
import { useHistory } from 'react-router';
import { ButtonLink } from '@naf/button-link';
import { Text, TextVariant } from '@naf/text';
import { GridCol } from '@naf/grid';
import * as S from './Styles';
import BenefitsList from '../../components/benefits-list/BenefitsList';
import StA from '../../styles/articles/StyledArticle';

type RelatedBenefitsProps = {
  relatedContent: [];
};

const RelatedBenefits = ({ relatedContent }: RelatedBenefitsProps) => {
  const history = useHistory();
  const handleLinkClick = () => {
    const pathArr = window?.location?.pathname.split('/');
    const parentPath = pathArr?.slice(0, pathArr.length - 2).join('/'); // remove /fordeler/<benefit>
    history.push(`${parentPath}?tab=benefits`);
  };

  return (
    <S.StyledGridRow>
      <GridCol s="12" m="12" l="12" xl="12">
        <StA.ReadMoreHRLine />
        <S.ReadMoreWrapper>
          <Text tag="h2" variant={TextVariant.Header3}>
            Relevante fordeler
          </Text>
          <ButtonLink text="Se alle medlemsfordeler" variant="secondary" onClick={handleLinkClick} />
        </S.ReadMoreWrapper>
        <BenefitsList benefitItems={relatedContent} size="standard" />
      </GridCol>
    </S.StyledGridRow>
  );
};

export default RelatedBenefits;
