import React from 'react';
import { TextVariant } from '@naf/text';
import { Grid, GridCol, GridRow } from '@naf/grid';
import { useWindowLocation } from '../../../hooks/Window/useWindowLocation';
import useSelector from '../../../redux/typedHooks';
import { useAuth0Token } from '../../../hooks/useAuth0Token';
import { useDocument } from '../../../hooks/useDocument';
import { LoaderContent } from '../../LoaderPage';
import SchemaArticle from '../../../components/structured-data/SchemaArticle';
import { useSendGTMEventOnce } from '../../../hooks/GTM/useSendGTMEventOnce';
import BreadCrumb from '../../../components/breadcrumb/BreadCrumb';
import StA from '../../../styles/articles/StyledArticle';
import BlockContent from '../../../components/block-content/BlockContent';
import FeedbackSurvey from '../../../components/feedbacksurvey/FeedbackSurvey';
import * as S from '../../BenefitArticle/Styles';
import { ArticleType } from '../../../../../types/articleType';
import { cloudinaryImage } from '../../../utils/imageUrl';
import { MetaData } from '../../../components/metaData/MetaData';
import { InternalSearchCardType } from '../../../components/layout/InternalSearchMetaData';
import { LayoutWithMainContent } from '../../../components/layout/Layout';
import RelatedBenefits from '../../BenefitArticle/RelatedBenefits';
import { NotFound } from '../../../loadable-elements/NotFound';

type Props = {
  match: {
    params: { slug: string };
  };
};

export const LocalArticle = ({
  match: {
    params: { slug },
  },
}: Props) => {
  const windowPath = useWindowLocation();
  const { simpleToken } = useAuth0Token();
  const {
    data,
    meta: { isUpdating },
  } = useDocument(slug, simpleToken);
  const article = data as ArticleType;

  const application = useSelector((state) => state.application);
  const url = useWindowLocation();
  const categoriesState = useSelector((state) => state.sitestructure);
  const mappedCategory = article?.category?.slug
    ? categoriesState?.mappedCategories[article?.category?.slug].data
    : null;

  useSendGTMEventOnce(
    article && {
      page_type: 'LocalArticle',
      pageCategory: `${mappedCategory?.prettyUrl}/${article.name}`,
      contentId: article.id,
    },
    [article],
  );

  if (!data && isUpdating) {
    return <LoaderContent />;
  }
  if (!data) {
    return <NotFound />;
  }

  const {
    name,
    ingress,
    meta,
    body,
    video,
    localArticleImage,
    relatedContent,
    seoConfig,
    metaDisplay,
    openGraphImage,
    tags,
  } = article;
  const author = meta?.editor?.name;

  const cldOpenGraphImage = openGraphImage && cloudinaryImage(openGraphImage.publicId, application);

  return (
    <LayoutWithMainContent
      title={name}
      description={ingress}
      isHiddenFromSearch={seoConfig?.isHiddenFromSearch}
      seoDescription={seoConfig?.introduction || undefined}
      seoTitle={seoConfig?.title || undefined}
      imgUrl={localArticleImage?.url}
      internalSearchMetaData={{
        cardType: InternalSearchCardType.LocalArticle,
        imageUrl: localArticleImage?.url,
        tags,
      }}
      url={url}
    >
      <SchemaArticle
        title={name}
        description={ingress}
        imgUrl={cldOpenGraphImage || localArticleImage?.url}
        datePublished={meta?.publishAt}
        dateModified={meta?.updatedAt}
        url={url}
        id={slug}
        author={author}
      />
      <Grid>
        <GridRow>
          <GridCol s="12" m="12" l="12" xl="12">
            <BreadCrumb />
            <GridRow>
              <GridCol s="12" m="12" l="8" xl="8">
                {localArticleImage?.url && !video && (
                  <S.ImageWrapper>
                    <S.ImageFigure>
                      <img src={localArticleImage?.url} alt={localArticleImage?.caption || ''} />
                    </S.ImageFigure>
                  </S.ImageWrapper>
                )}
                {name && (
                  <S.StyledHeaderText tag="h1" variant={TextVariant.Header1}>
                    {name}
                  </S.StyledHeaderText>
                )}
              </GridCol>
            </GridRow>
            <GridRow>
              <GridCol s="12" m="12" l="8" xl="8">
                {ingress && (
                  <>
                    <StA.Ingress tag="p" variant={TextVariant.Ingress}>
                      {ingress}
                    </StA.Ingress>
                    <StA.HRLine />
                  </>
                )}
                {body ? (
                  <StA.Body>
                    <BlockContent value={body} />
                  </StA.Body>
                ) : (
                  <LoaderContent />
                )}
                <MetaData meta={meta} metaDisplay={metaDisplay} />
              </GridCol>
            </GridRow>
          </GridCol>
        </GridRow>
        {relatedContent && relatedContent.length > 0 && <RelatedBenefits relatedContent={relatedContent} />}
        {!metaDisplay?.hideForm && (
          <GridRow>
            <GridCol s="12" m="12" l="8" xl="8">
              <FeedbackSurvey contentUrl={windowPath} />
            </GridCol>
          </GridRow>
        )}
      </Grid>
    </LayoutWithMainContent>
  );
};

export default LocalArticle;
