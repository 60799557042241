import { Text, TextVariant } from '@naf/text';
import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import Logo from '../../../../assets/images/logo.png';
import mcClub from '../../../../assets/images/MC-icon.svg?url';
import localDepartment from '../../../../assets/images/local-dep-icon.svg?url';
import S from '../Styles';
import { NafLoactionSlugs, useHandleNafLocationsRoutes } from '../useHandleNafLocationsRoutes';

type Props = {
  organizer: { title: string; type: string; slug: string };
};

const OrganizerHeader = ({ organizer: { type, title, slug } }: Props) => {
  const { locations } = useHandleNafLocationsRoutes();
  if (type === 'LocalDepartment') {
    return (
      <StyledLink to={`${locations[NafLoactionSlugs.localDepartment].fullUrl}/${slug}`}>
        <S.IconHeader>
          <OrganizerIcon src={localDepartment} alt="lokalavdeling logo" />
          <Text variant={TextVariant.Tag}>{title}</Text>
        </S.IconHeader>
      </StyledLink>
    );
  }
  if (type === 'McClub') {
    return (
      <StyledLink to={`${locations[NafLoactionSlugs.mcClubs].fullUrl}/${slug}`}>
        <S.IconHeader>
          <OrganizerIcon src={mcClub} alt="MC klubb logo" />
          <Text variant={TextVariant.Tag}>{title}</Text>
        </S.IconHeader>
      </StyledLink>
    );
  }
  return (
    <S.IconHeader>
      <OrganizerIcon src={Logo} alt="NAF Logo" />
      <Text variant={TextVariant.Tag}>{title}</Text>
    </S.IconHeader>
  );
};

const StyledLink = styled(Link)`
  text-decoration: none;
`;

const OrganizerIcon = styled.img`
  height: 32px;
  width: 32px;
`;

export default OrganizerHeader;
